import './Resume.css'
import noloniImg from '../images/jobref_noloni.jpg'
import feImg from '../images/jobref_fe.jpg'
import wigaImg from '../images/jobref_wiga.jpg'
// import jobSessionImg from '../images/projref_jobsess.png'
// import pubLive from '../images/projref_publive.png'
import React from 'react'
import * as Scroll from 'react-scroll'

const ScrollLink = Scroll.Link;


const BottomBar = () => {
    return (
        <div className='r-bottom-menu-container'>
            <div className='r-bottom-menu'>
                <ScrollLink 
                    smooth={true} 
                    duration={500} 
                    className='r-menu-item' 
                    to="intro">Top</ScrollLink>

                <div className='r-bottomCenter'>
                    Benjamin Howell - Copyright &copy; 2022
                </div>
            </div>
            
        </div>
    );
}


const AdditionalSkills = () => {

    return (
        <>
            {/* <h3>Languages & Technologies</h3> */}
            <h4>Languages</h4>
            <ul>
                <li>english</li>
                <li>deutsch</li>
            </ul>
            <h4>Other Skills</h4>
            <ul>
                <li>Legal drafting <br/> (Terms & GDPR)</li>
                <li>BPMN basics</li>
                <li>Show & Eventmanagment</li>
                <li>Sound & Music Production</li>
            </ul>
        </>
    );
}


export const ASkills = () => {

    return ( 
        <div className="r-mobile">
            <AdditionalSkills />
        </div>
    );
}


export const ProjectCard = (prop) => {

    const imgLink = prop.imgLink ?? '#';
    const cardImg = prop.cardImg;
    const title = prop.title?? 'empty';
    const keyPoints = prop.keyPoints?? ['empty'];

    return (
        <div className="r-card">
            <a className='r-card-Img card-item' href={imgLink} target='_blank' rel="noreferrer">
                <img src={cardImg} alt="" width='100px' height='100px' />
            </a>
            <div className='r-card-title card-item'><strong>{title}</strong></div>
            <p className='card-item'>
                <ul>
                    {keyPoints.map( el => {
                        return (<li>{el}</li>);
                    })}
                </ul>
            </p>   

        </div>
    );
}


export const Resume = () => {
  return (
    <div className='r' id='references'>
        <div className="r-left">
            <div className="r-left-wrapper">
                <div className="r-title">
                    <h2>Jobs & Projects</h2>
                </div>
                <div style={{paddingBottom: '30px'}}>
                    <h3>Companies</h3>
                    <ul style={{ marginBottom: '40px' }}>
                        <li><strong>Northdale UG - Software Developer</strong> August 2021 - May 2022
                        <br/>Successfully worked on two major projects. Gained team development XP on the noloni app dev team.<br/>
                        </li>
        
                            <ProjectCard cardImg={noloniImg} imgLink='https://www.noloni.de' title='Noloni - Elderly Communication App' keyPoints={[
                                'Fullstack CRM Integration',
                                'Co-Conceptualisation & Co-Implementation of a microservice-ish architecture',
                                'React Native & Node (TS/JS coding & codereviews)',
                                'Native bridging code for Android(Java) & iOS(Obj-C)',
                                'Crossplattform Compilations & Integration/End-to-end Testing (Android & iOS)',
                                'Various Server DevOps tasks (including the handling of mariadb, nginx, docker & SSL configurations & deployments) '
                            ]}/>

                            <ProjectCard cardImg={feImg} title='Floorplanextractor Application - 3D to floorplan converter' keyPoints={[
                                'Additional C/C++ and OOP XP',
                                'Basics in GLUT & OpenGL',
                                'CGAL mesh & graph handling',
                                'Applying Growing Region, Nearest-k & other algorithms'
                            ]}/>
                    </ul>
                    <ul>
                        <li><strong>Arnold-Kuthe Entertainment GmbH - Vice Stage Manager / Stage Tech</strong> September 2014 - July 2021
                        <br/></li>

                        <ProjectCard cardImg={wigaImg} imgLink='https://www.wintergarten-berlin.de' title='Stage Management & Technical Operations' keyPoints={[
                            'Event & Artist Managment',
                            'Operating of robots & programming of lift motors',
                            'Hands-On end-user testing of Eventix ticketing system',
                            'Light & Sound Operations',
                            'Various technical Stage Operations & Maintenance XP',
                        ]}/>
                    </ul>
                </div>
                <div>
                    <h3>Own Projects</h3>
                    <ul>
                        <li><strong>Pub-Live - Eventmangement Web App </strong> (Work-In-Progress)
                        <br/>Working on fullstack dev & server devOps including SQL, NGINX, node, react</li>

                    </ul>
                    <ul>
                        <li><strong>Job Calendar - iOS App</strong> More than a clock-in app (publishing & further development on hold) 
                        <br/>Gained XP: iOS Development in Swift (using Combine, CoreData, MVVM-C, etc... also basics in SwiftUI and Obj-C) & basic Server DevOps</li>

                    </ul>
                    <ul>
                        <li><strong>Programming of ESP 32 + ATmega 328 MCUs</strong><br/>(including some with a Temperature & Humidity Sensor and with a micro display) 
                        <br/>Gained XP: Embedded C++ and a little ASM & some basics in microelectronics</li>

                    </ul>
                </div>
            </div>

            <BottomBar />
        </div>
        <div className="r-right">
            <div className='r-right-wrapper'>
                <AdditionalSkills />
            </div>
        </div>
    </div>
  )
}


