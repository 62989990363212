import React from 'react'
import * as Scroll from 'react-scroll'
import './Intro.css'
//import me from '../images/me-transp-color-image-final.png' 
import me2 from '../images/me-color.jpg'

const ScrollLink = Scroll.Link;

const MobileMenu = () => {

    const resetCallback = () => {
        const chkbox = document.getElementById('toggle1');
        chkbox.checked = false;
    }

    return (
        <section className="p-menu1">
            <nav id="navbar" className="navigation" role="navigation">
                <input id="toggle1" type="checkbox" />
                <label className="hamburger1" for="toggle1">
                    <div className="top"></div>
                    <div className="meat"></div>
                    <div className="bottom"></div>
                </label>
                <nav className="menu1">
                    <ScrollLink smooth={true} 
                        duration={500} 
                        className="link1" 
                        to="offer"
                        onClick={resetCallback}>My Offer</ScrollLink>
                    <ScrollLink smooth={true} 
                        duration={500} 
                        className="link1" 
                        to="references"
                        onClick={resetCallback}>References</ScrollLink>
                    <a className="link1" 
                        href="mailto: contact@benjaminhowell.pro"
                        onClick={resetCallback}>Contact</a>
                </nav>
            </nav>
        </section>
    );
}


const DesktopMenu = () => {

    return (
        <div className='i-desktop-menu-container'>
            <div className='i-desktop-menu'>
                <ScrollLink 
                    smooth={true} 
                    duration={500} 
                    className='d-menu-item' 
                    to="references">References</ScrollLink>
                <a className='d-menu-item' href="mailto: contact@benjaminhowell.pro">Contact</a>
            </div>
        </div>
    );
}


function Offer() {

    return (
        <>
            <div className='i-contact' style={{ flex: '1', marginTop: '20px' }}>
                <h4>Currently based in </h4>
                    <tab>Berlin, Germany</tab>
                <br/><br/><br/>
                <h4>Work ability:</h4>
                <ul>
                    <li>Remote or on-site</li>
                    <li>May travel world-wide once or twice per week</li>
                    <li>Part time or full time</li>
                    <li>Selfemployed contract or full employment possible</li>
                </ul>
                <br/>
                
            </div>
            <div className="i-contact" id='contactEmail'> 
                <br/><br/> <a href='mailto: contact@benjaminhowell.pro'><strong>contact@benjaminhowell.pro</strong></a>
            </div>
        </>
    );
}


function MyMobileOffer() {

    return (
        <div className="i-offer-mobile" id="offer">
            <Offer />
        </div>
    );
}


export const MyOffer = (props) => {

    const id = props.id ?? '';

    if (id === 'mobileOffer') {
        return (
            <MyMobileOffer />
        );
    }
    return (
        <>
        </>
    );
}


export const Intro = () => {
  return (
    <div className='i' id='intro'>
        <div className="i-mobile">
            <MobileMenu />
            <img className="i-me-img-mobile" src={me2} alt=''></img>
        </div>
        
        <div className="i-left">
            <DesktopMenu />
            <div className="i-left-wrapper">
                <h4 className='i-intro'>Hello, i am</h4>
                <h1 className='i-name'>Benjamin Howell</h1>
                <div className="i-title-main">
                        Software Developer
                </div>
                <div className="i-skills">
                    <div className="i-skills-wrapper">
                        <div className="i-skills-item"><strong>Fullstack</strong> Mobile & Web Applications</div>
                        <div className="i-skills-item"><strong>Swift</strong> & iOS Specialisation</div>
                        <div className="i-skills-item"><strong>React</strong> (+Native) JS/TS</div>
                        <div className="i-skills-item"><strong>Crossplatform</strong> Compilations (Xcode/Android Studio with Metro) including basics in Java and Obj-C</div>
                        <div className="i-skills-item"><strong>Low Level</strong> Programming C/C++, CGAL & basics in OpenGL</div>
                        <div className="i-skills-item"><strong>Sever DevOps</strong> (Node, SQL, NoSQL, NGINX, Docker, admin via SSH, SSL, etc)</div>
                        <div className="i-skills-item"><strong>XP</strong> with OOP, Functional, DDD, Patterns, MVVM-C, MVM, etc...</div>
                    </div>
                </div>
                <div className='i-desc'>
                    My <strong>Objective</strong> is to efficiently create business value while focusing on the business domain, re-integrating overall user feedback in appropriate cycles or in continuous development as a team player, learning and using new technologies wherever it makes sense, such as low and high-level APIs and creating great products which offer great experiences and value to the user. This objective sits right at the heart of my developer dream job and my passion.
                </div>
            </div>
        </div>
        <div className="i-right">
            <div className="i-right-wrapper">
                <img className='i-me-img' src={me2} alt=''/>
            </div>
            
            <Offer />
        </div>
        
    </div>
  )
}
