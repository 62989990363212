import './style.css'
import { Intro, MyOffer } from './components/Intro';
import { Resume, ASkills } from './components/Resume';


function App() {
  return (
    <div className='app-wrapper'>
        <Intro />
        <MyOffer id='mobileOffer' />
        <ASkills />
        <Resume />
    </div>
  );
}

export default App;
